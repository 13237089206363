

import React from "react"
import Navbar from "../inc/Navbar";
import Book_class_ii from "../images/BookAdpublished~2.jpg";
import {Link} from "react-router-dom";
import Book_class_iii from  "../images/book gi.jpeg"
import classiiibook from "../images/GK GI Book for Class III IV V.pdf";

function Book() {
    return (
        <>
            <Navbar />
            <br />
            <br />
            <br />
       
            <br />
<div className="bookbackground">
            <div className="album py-1">
                <div className="container mt-4">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Book</li>
                        </ol>
                    </nav>
                </div>
            </div>
            </div>

<br/>
            <div className="album py-2">
                <div className="container">
                    <div className="row">

                        <div className="col-md-6 mt-3">
                            <div className="card">
                                <img src={Book_class_ii} alt="book_class_ii" />
                            </div>
                        </div>

                        <div className="col-md-6 mt-3">
                            <div className="card">
                                <a href={classiiibook}>
                                <div className="btn-grad45"> GK GI Book for Class III IV V Download <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-down" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1z"/>
  <path fill-rule="evenodd" d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z"/>
</svg></div></a>
                                <img src={Book_class_iii} alt="Book_class_iii"/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <br />

        </>
    );
}

export default Book;